.main-body .content > .titlePage h1 {
  text-align: center;
  padding: 0; }

.main-body .content > .richContent h2 {
  text-align: center;
  font-size: 1.25em;
  color: #096b3b; }

.main-body .content > .richContent p {
  text-align: justify; }

.home-menu {
  padding-top: 1em;
  padding-bottom: 4em; }

.home-menu-item {
  position: relative;
  padding: 3em;
  transition: transform 0.25s; }
  .home-menu-item:after,
  .home-menu-item > .bt {
    content: '';
    display: block;
    position: absolute;
    top: 1em;
    right: 1em;
    bottom: 1em;
    left: 1em; }
  .home-menu-item:after {
    z-index: -1;
    background-color: #f4f4f4;
    border: solid 1px #EEE;
    border-radius: 1em;
    transition: background-color 0.25s linear, box-shadow 0.25s ease-in-out; }
  .home-menu-item > .bt {
    text-indent: -9999px; }
  .home-menu-item::before {
    content: '';
    display: block;
    width: 140px;
    height: 67px;
    margin: 0 auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center; }
  .home-menu-item .home-menu-item-title {
    display: block;
    font-size: 1.563em;
    font-weight: bold;
    margin-bottom: 0.5em;
    text-align: center; }
  .home-menu-item:hover {
    transform: translateY(-0.5em); }
    .home-menu-item:hover:after {
      background-color: #FFF;
      box-shadow: 0 0.5em 1em 0 rgba(0, 0, 0, 0.1); }
  @media only print, only screen and (min-width: 30.063em) {
    .home-menu-item {
      padding-left: calc( 5em + 140px); }
      .home-menu-item::before {
        position: absolute;
        left: 3em;
        top: 50%;
        transform: translateY(-50%); }
      .home-menu-item .home-menu-item-title {
        text-align: left; } }
  @media only print, only screen and (min-width: 60.063em) {
    .home-menu-item {
      padding-left: 3em; }
      .home-menu-item::before {
        position: static;
        left: auto;
        top: auto;
        transform: none; }
      .home-menu-item .home-menu-item-title {
        text-align: center; } }

.css3slider {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden; }

.css3slider-item {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition-delay: 0s; }
  .css3slider-item > img {
    display: none; }
  .trans-fade .css3slider-item.active {
    opacity: 1; }
  .trans-accordeon .css3slider-item {
    opacity: 1; }
    .trans-accordeon .css3slider-item:first-child {
      transform: translateX(0%); }
  .trans-ltr .css3slider-item,
  .trans-rtl .css3slider-item,
  .trans-btt .css3slider-item,
  .trans-ttb .css3slider-item {
    opacity: 1; }

.zooms {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  overflow: hidden;
  background-color: #EEE;
  margin-bottom: 1em; }
  @media only print, only screen and (min-width: 30.063em) {
    .zooms {
      padding-bottom: 60%; } }
  @media only print, only screen and (min-width: 48.063em) {
    .zooms {
      padding-bottom: 40%; } }
  @media only print, only screen and (min-width: 75.063em) {
    .zooms {
      height: 30em;
      padding-bottom: 0; } }
  .zooms > .items {
    margin: 0;
    padding: 0; }
  .zooms .zooms-item-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../images/bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    .zooms .zooms-item-box:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url("../images/bg_grille.gif"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
        .zooms .zooms-item-box:before {
          background-size: 1px 2px; } }
    .zooms .zooms-item-box .row {
      position: absolute;
      bottom: 2em;
      left: 50%;
      transform: translateX(-50%); }
      .zooms .zooms-item-box .row .column {
        padding: 0 2em; }
    .zooms .zooms-item-box .zooms-item-content {
      font-size: 2.66vw; }
      @media only print, only screen and (min-width: 30.063em) {
        .zooms .zooms-item-box .zooms-item-content {
          font-size: 2vw; } }
      @media only print, only screen and (min-width: 48.063em) {
        .zooms .zooms-item-box .zooms-item-content {
          font-size: 1.33vw; } }
      @media only print, only screen and (min-width: 75.063em) {
        .zooms .zooms-item-box .zooms-item-content {
          font-size: 1em; } }
    .zooms .zooms-item-box .zoom-item-title {
      position: relative;
      display: block;
      float: left;
      font-size: 1.953em;
      margin-bottom: 0.5em;
      color: #096b3b;
      padding: 0 1em;
      white-space: nowrap; }
      .zooms .zooms-item-box .zoom-item-title:before {
        content: '';
        position: absolute;
        width: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #096b3b;
        transition: width 0.5s ease-in-out;
        transition-delay: 0.5s; }
      .zooms .zooms-item-box .zoom-item-title:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        bottom: 0;
        overflow: hidden;
        margin: 0 1em;
        content: attr(data-content);
        color: #FFF;
        transition: width 0.5s ease-in-out;
        transition-delay: 0.5s; }
    .zooms .zooms-item-box .zoom-item-overview {
      clear: both;
      float: left;
      background-color: #FFF;
      color: #414599;
      font-size: 1.25em;
      line-height: 1.4;
      padding: 0.25em 1.563em;
      margin-bottom: 1.5em;
      margin-left: 1.563em;
      transform: translateX(100%);
      transition: transform 1s ease-out, opacity 0.75s linear;
      opacity: 0; }
    .zooms .zooms-item-box .zoom-item-link {
      clear: both;
      float: left;
      background-color: #FFF;
      color: #414599;
      font-weight: bold;
      line-height: 2.25em;
      height: 2.5em;
      padding: 0 1em;
      border-radius: 1.25em;
      border: solid 2px #414599;
      transform: translateY(300%);
      transition: transform 0.5s ease-out, opacity 0.5s linear;
      transition-delay: 1s;
      opacity: 0; }
  .zooms .zooms-item.active .zoom-item-overview,
  .zooms .zooms-item.active .zoom-item-link, .zooms .zooms-item.previous .zoom-item-overview,
  .zooms .zooms-item.previous .zoom-item-link {
    transform: none;
    opacity: 1; }
  .zooms .zooms-item.active .zoom-item-title:before, .zooms .zooms-item.active .zoom-item-title:after, .zooms .zooms-item.previous .zoom-item-title:before, .zooms .zooms-item.previous .zoom-item-title:after {
    width: 100%; }
  .zooms .css3slider-next,
  .zooms .css3slider-prev {
    position: absolute;
    top: 50%;
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
    z-index: 99;
    text-decoration: none;
    text-align: center;
    color: #FFF;
    background: rgba(0, 0, 0, 0.25); }
    .zooms .css3slider-next:before,
    .zooms .css3slider-prev:before {
      display: block;
      font-size: 3rem;
      line-height: 0.75; }
    .zooms .css3slider-next:hover,
    .zooms .css3slider-prev:hover {
      color: #FFF;
      background: rgba(0, 0, 0, 0.5); }
  .zooms .css3slider-next {
    right: 0;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em; }
    .zooms .css3slider-next:before {
      content: '\203A'; }
  .zooms .css3slider-prev {
    left: 0;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em; }
    .zooms .css3slider-prev:before {
      content: '\2039'; }

.main-body .content {
  padding-top: 1em;
  padding-bottom: 2em; }
  .main-body .content .titlePage {
    max-width: 860px;
    margin: 0 auto; }
